import { useEffect, useState } from "react";
import { DeviceAPI } from "../../../apis/DeviceAPI";
import { useAuth } from "../../../contexts/auth";
import { AxiosError, isAxiosError } from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  PencilIcon,
  UserPlusIcon,
  XCircleIcon,
  ChartBarIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { Container, Main } from "./styled";
import { disable } from "workbox-navigation-preload";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { usePreloader } from "../../../contexts/preloader";
import { useDialog } from "../../../contexts/dialog";
import { BrowserView, MobileView } from "react-device-detect";

const DeviceProtected = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setAuthMessage } = useAuth();
  const { setStartPreloader } = usePreloader();
  const { setMessageDialog, setActionDialog } = useDialog();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [device, setDevice] = useState<any | null>(null);
  const [filtroBusca, setFiltroBusca] = useState("");
  const [titulos, setTítulos] = useState([
    "Nome",
    "Grupo",
    "Categoria",
    "Permissão",
    "Mac",
    "Ações",
  ]);
  const [titulosMobile, setTítulosMobile] = useState(["Nome", "Ações"]);

  const loadPrevPage = () => {
    if (device?.current_page > 1) {
      DeviceAPI.listDevice({
        token: user?.token,
        page: device.current_page - 1,
      })
        .then((res: any) => {
          setDevice(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }
  };

  const loadNextPage = () => {
    if (device?.current_page < device?.last_page) {
      DeviceAPI.listDevice({
        token: user?.token,
        page: device.current_page + 1,
      })
        .then((res: any) => {
          setDevice(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }
  };

  const verDispositivo = (id: number) => {
    navigate(`/verdispositivo/${id}`);
  };

  const editaDispositivo = (id: number) => {
    navigate(`/editardispositivo/${id}`);
  };

  const excluirDispositivo = (id: number, name: string) => {
    setMessageDialog(`Confirma exclusão do dispositivo ${name}?`);
    setActionDialog(() => () => excluirDispositivoConfirma(id));
  };

  const excluirDispositivoConfirma = (id: number) => {
    setStartPreloader(true);
    DeviceAPI.deleteDevice({ token: user?.token, id: id })
      .then((res: any) => {
        setStartPreloader(false);
        navigate("/");
        setTimeout(() => navigate("/dispositivos"), 1000);
        setAuthMessage("Dispositivo excluído com sucesso");
      })
      .catch((err: Error | AxiosError) => {
        setStartPreloader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    let admin = false;
    user?.roles.map((roleUser: string, index: number) => {
      if (roleUser === "admin") {
        admin = true;
      }
    });
    setIsAdmin(admin);
    DeviceAPI.listDevice({
      token: user?.token,
      page: null,
    })
      .then((res: any) => {
        console.log(res);
        setDevice(res);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      });
  }, [user]);

  const row = (
    index: number,
    id: number,
    name: string,
    mac: string,
    group: string,
    category: string,
    canControl: boolean
  ) => {
    const isLast = index === device.data.length - 1;
    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
    return (
      <tr key={name}>
        <td className={classes}>
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {name}
              </Typography>
            </div>
          </div>
        </td>
        <td className={classes}>
          <div className="flex flex-col">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              {group}
            </Typography>
          </div>
        </td>
        <td className={classes}>
          <div className="flex flex-col">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              {category}
            </Typography>
          </div>
        </td>
        <td className={classes}>
          <div className="flex flex-col">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              {canControl ? "Controle" : "Monitoramento"}
            </Typography>
          </div>
        </td>
        <td className={classes}>
          <div className="flex flex-col">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              {mac}
            </Typography>
          </div>
        </td>
        <td className={classes}>
          <Tooltip content="Dados">
            <IconButton
              variant="text"
              className="text-secondary"
              onClick={() => verDispositivo(id)}
            >
              <ChartBarIcon className="h-4 w-4" />
            </IconButton>
          </Tooltip>
          {isAdmin && (
            <>
              <Tooltip content="Editar grupo">
                <IconButton
                  variant="text"
                  className="text-secondary"
                  onClick={() => {
                    editaDispositivo(id);
                  }}
                >
                  <PencilIcon className="h-4 w-4" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Excluir grupo">
                <IconButton
                  variant="text"
                  className="text-danger"
                  onClick={() => excluirDispositivo(id, name)}
                >
                  <XCircleIcon className="h-6 w-6" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </td>
      </tr>
    );
  };

  const rowMobile = (id: number, index: number, name: string) => {
    const isLast = index === device.data.length - 1;
    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
    return (
      <tr key={name}>
        <td className={classes}>
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {name}
              </Typography>
            </div>
          </div>
        </td>
        <td className={classes}>
          <Tooltip content="Dados">
            <IconButton
              variant="text"
              className="text-secondary"
              onClick={() => verDispositivo(id)}
            >
              <ChartBarIcon className="h-4 w-4" />
            </IconButton>
          </Tooltip>
          {isAdmin && (
            <>
              <Tooltip content="Editar grupo">
                <IconButton
                  variant="text"
                  className="text-secondary"
                  onClick={() => editaDispositivo(id)}
                >
                  <PencilIcon className="h-4 w-4" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Excluir grupo">
                <IconButton
                  variant="text"
                  className="text-danger"
                  onClick={() => excluirDispositivo(id, name)}
                >
                  <XCircleIcon className="h-6 w-6" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <Container>
      <Main>
        <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 flex items-center justify-between gap-8">
              <div>
                <Typography variant="h5" color="blue-gray">
                  Dispositivos
                </Typography>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                {isAdmin && (
                  <Button
                    className="flex items-center gap-3 bg-secondary"
                    size="sm"
                    onClick={() => navigate("/adicionardispositivo")}
                  >
                    <UserPlusIcon strokeWidth={2} className="h-4 w-4" />
                    Adicionar Dispositivo
                  </Button>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <div className="w-full md:w-72">
                <Input
                  label="Buscar"
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  onChange={(e) => setFiltroBusca(e.target.value)}
                />
              </div>
            </div>
          </CardHeader>
          <CardBody className=" px-0">
            <BrowserView>
              <table className="mt-4 w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th
                        key={titulo}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {titulo}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {device?.data.map(
                    (
                      {
                        id,
                        name,
                        mac,
                        group,
                        canControl,
                      }: {
                        id: number;
                        name: string;
                        mac: string;
                        group: any;
                        canControl: boolean;
                      },
                      index: number
                    ) => {
                      if (filtroBusca !== "") {
                        if (name.search(filtroBusca) !== -1) {
                          return row(
                            index,
                            id,
                            name,
                            mac,
                            group.name,
                            group.category.name,
                            canControl
                          );
                        }
                      } else {
                        return row(
                          index,
                          id,
                          name,
                          mac,
                          group.name,
                          group.category.name,
                          canControl
                        );
                      }
                    }
                  )}
                </tbody>
              </table>
            </BrowserView>
            <MobileView>
              <table className="mt-4 w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {titulosMobile.map((titulo) => (
                      <th
                        key={titulo}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {titulo}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {device?.data.map(
                    (
                      {
                        id,
                        name,
                      }: {
                        id: number;
                        name: string;
                      },
                      index: number
                    ) => {
                      if (filtroBusca !== "") {
                        if (name.search(filtroBusca) !== -1) {
                          return rowMobile(id, index, name);
                        }
                      } else {
                        return rowMobile(id, index, name);
                      }
                    }
                  )}
                </tbody>
              </table>
            </MobileView>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Página {device?.current_page} de {device?.last_page}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="sm"
                onClick={loadPrevPage}
                disabled={device?.current_page === 1}
              >
                Anterior
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={loadNextPage}
                disabled={device?.current_page === device?.last_page}
              >
                Próxima
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Main>
    </Container>
  );
};

export default DeviceProtected;
