import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ComparisonAPI = {
  getComparisonId: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/comparison/${id}
      }`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getComparison: async function ({ token, id1, id2 }, cancel = false) {
    const response = await api.request({
      url: `/comparison${
        id1 !== null && id2 === null
          ? `?id_1=${id1}`
          : id1 === null && id2 !== null
          ? `?id_2=${id2}`
          : id1 !== null && id2 !== null
          ? `?id_1=${id1}&id_2=${id2}`
          : ""
      }`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  createComparison: async function ({ token, dados }, cancel = false) {
    const response = await api.request({
      url: `/comparison`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: dados,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  deleteComparison: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/comparison/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  updateComparison: async function ({ token, id, dados }, cancel = false) {
    const response = await api.request({
      url: `/comparison/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: dados,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ComparisonAPI);
