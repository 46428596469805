type AnyObject = { [key: string]: any };

export function flattenNestedArray(inputArray: AnyObject[]): AnyObject[] {
  const result: AnyObject[] = [];

  inputArray.forEach((item) => {
    const flatObject: AnyObject = {};
    flatten(item, flatObject);
    result.push(flatObject);
  });

  function flatten(obj: AnyObject, flatObject: AnyObject, path: string = "") {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((element: AnyObject, index: number) => {
          const newPath = `${path}${key}_${index + 1}_`;
          if (typeof element === "object" && element !== null) {
            flatten(element, flatObject, newPath);
          } else {
            flatObject[newPath.slice(0, -1)] = element;
          }
        });
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        flatten(obj[key], flatObject, path + key + "_");
      } else {
        flatObject[path + key] = obj[key];
      }
    }
  }

  return result;
}
