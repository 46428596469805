import { fetchEventSource } from "@microsoft/fetch-event-source";
import { AxiosError, isAxiosError } from "axios";
import { userProps } from "../../contexts/auth";
import { serverBaseURL } from "../configs/axiosConfigs";
import { DeviceAPI } from "../DeviceAPI";
import { GroupsAPI } from "../GroupsAPI";

let controller: AbortController;
let controllerCompare: AbortController;

export const fetchData = async (
  dadosAtual: any,
  user: userProps | null,
  deviceId: any,
  deviceIdCompare: any,
  setData: any,
  setDados: any,
  setGrupoEscolhido: any,
  setStartPreloader: any,
  setGrupos: any,
  lastDatetime: any,
  setLastaDatetime: any,
  dadosAtualCompare: any,
  setDataCompare: any,
  setDadosCompare: any,
  setGrupoEscolhidoCompare: any,
  lastDatetimeCompare: any,
  setLastaDatetimeCompare: any
) => {
  if (user && deviceId) {
    try {
      controller.abort();
    } catch (err) {
      console.log(err);
    }
    controller = new AbortController();

    fetchEventSource(`${serverBaseURL}/devices/${deviceId}/status`, {
      method: "GET",
      headers: {
        Accept: "text/event-stream",
        Authorization: `Bearer ${user?.token}`,
      },
      signal: controller.signal,
      async onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      },
      onmessage(event) {
        DeviceAPI.getDevice({ token: user?.token, id: deviceId })
          .then((res: any) => {
            setData(res);
            setGrupoEscolhido(res.group_id);
            // console.log(res);
          })
          .catch((err: Error | AxiosError) => {
            setStartPreloader(false);
            console.log(err);
          });
        // console.log(event.data);
        const parsedData = JSON.parse(event.data);
        let datetime = new Array();
        let update = false;
        // console.log("inicio array final", dadosAtual);
        // console.log(parsedData);
        if (parsedData) {
          Object.keys(parsedData).map((aparelho, index) => {
            let dadosFinal = new Array();
            let propriedadesName = new Array();
            let propriedades = new Array();

            Object.keys(parsedData[aparelho]).map((propriedade, index2) => {
              if (propriedade === "datetime") {
                if (
                  lastDatetime !==
                  new Date(parsedData[aparelho][propriedade]).toLocaleString()
                ) {
                  setLastaDatetime(
                    new Date(parsedData[aparelho][propriedade])
                      .toLocaleString()
                      .replace(",", "")
                      .split(" ")
                  );
                  update = true;
                }
              }
            });
            if (update) {
              Object.keys(parsedData[aparelho]).map((propriedade, index2) => {
                if (
                  propriedade !== "controle" &&
                  propriedade !== "nome" &&
                  propriedade !== "datetime"
                ) {
                  propriedades.push(propriedade);
                  propriedadesName.push(parsedData[aparelho][propriedade].nome);

                  dadosFinal.push(
                    Number(parsedData[aparelho][propriedade].valor)
                  );
                } else if (propriedade === "datetime") {
                  datetime.push(
                    new Date(parsedData[aparelho][propriedade])
                      .toLocaleString()
                      .replace(",", "")
                      .split(" ")
                  );
                }
              });
              // console.log(datetime);

              let aparelhoExists = false;
              dadosAtual.map((aparelhoFinal: any, index2: number) => {
                if (aparelhoFinal.name === aparelho) {
                  aparelhoExists = true;
                  let propExists = false;
                  aparelhoFinal.labels.push(datetime[index]);
                  propriedades.map((prop, index) => {
                    aparelhoFinal.datasets.map(
                      (propriedadeFinal: any, index3: number) => {
                        if (propriedadeFinal.name === propriedades[index]) {
                          propExists = true;
                          propriedadeFinal.hidden = propriedadeFinal.hidden;
                          propriedadeFinal.data.push(dadosFinal[index]);
                        }
                      }
                    );
                  });

                  if (!propExists) {
                    aparelhoFinal.datasets.push({
                      name: propriedades[index],
                      label: propriedadesName[index],
                      hidden: false,
                      data: [dadosFinal[index]],
                    });
                  }
                }
              });
              if (!aparelhoExists) {
                dadosAtual.push({
                  name: aparelho,
                  labels: [datetime[index]],
                  datasets: propriedades.map((prop, index) => ({
                    name: prop,
                    label: propriedadesName[index],
                    hidden: false,
                    data: [dadosFinal[index]],
                  })),
                });
              }
            }
          });

          // console.log("fim array final", dadosAtual);
          setDados(dadosAtual);
        }
        // setStartPreloader(false);
        GroupsAPI.listGroups({
          token: user?.token,
          page: null,
        })
          .then((res2: any) => {
            // console.log("grupos", res2);
            setGrupos(res2.data);
          })
          .catch((err2: Error | AxiosError) => {
            if (isAxiosError(err2)) {
              console.log(err2.response?.data.message);
            }
          })
          .finally(() => {
            setStartPreloader(false);
          });
      },
      onclose() {
        console.log("Connection closed by the server");
      },
      onerror(err) {
        console.log("There was an error from server", err);
      },
    });
  }

  if (user && deviceIdCompare) {
    try {
      controllerCompare.abort();
    } catch (err) {
      console.log(err);
    }

    controllerCompare = new AbortController();

    fetchEventSource(`${serverBaseURL}/devices/${deviceIdCompare}/status`, {
      method: "GET",
      headers: {
        Accept: "text/event-stream",
        Authorization: `Bearer ${user?.token}`,
      },
      signal: controllerCompare.signal,
      async onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      },
      onmessage(event) {
        DeviceAPI.getDevice({ token: user?.token, id: deviceIdCompare })
          .then((res: any) => {
            setDataCompare(res);
            setGrupoEscolhidoCompare(res.group_id);
            // console.log(res);
          })
          .catch((err: Error | AxiosError) => {
            setStartPreloader(false);
            console.log(err);
          });
        // console.log(event.data);
        const parsedData = JSON.parse(event.data);
        let datetime = new Array();
        let update = false;
        // console.log("inicio array final", dadosAtual);
        // console.log(parsedData);
        if (parsedData) {
          Object.keys(parsedData).map((aparelho, index) => {
            let dadosFinal = new Array();
            let propriedadesName = new Array();
            let propriedades = new Array();

            Object.keys(parsedData[aparelho]).map((propriedade, index2) => {
              if (propriedade === "datetime") {
                if (
                  lastDatetimeCompare !==
                  new Date(parsedData[aparelho][propriedade]).toLocaleString()
                ) {
                  setLastaDatetimeCompare(
                    new Date(parsedData[aparelho][propriedade])
                      .toLocaleString()
                      .replace(",", "")
                      .split(" ")
                  );
                  update = true;
                }
              }
            });
            if (update) {
              Object.keys(parsedData[aparelho]).map((propriedade, index2) => {
                if (
                  propriedade !== "controle" &&
                  propriedade !== "nome" &&
                  propriedade !== "datetime"
                ) {
                  propriedades.push(propriedade);
                  propriedadesName.push(
                    parsedData[aparelho][propriedade].nome + "(2)"
                  );

                  dadosFinal.push(
                    Number(parsedData[aparelho][propriedade].valor)
                  );
                } else if (propriedade === "datetime") {
                  datetime.push(
                    new Date(parsedData[aparelho][propriedade])
                      .toLocaleString()
                      .replace(",", "")
                      .split(" ")
                  );
                }
              });
              // console.log(datetime);

              let aparelhoExists = false;
              dadosAtualCompare.map((aparelhoFinal: any, index2: number) => {
                if (aparelhoFinal.name === aparelho) {
                  aparelhoExists = true;
                  let propExists = false;
                  aparelhoFinal.labels.push(datetime[index]);
                  propriedades.map((prop, index) => {
                    aparelhoFinal.datasets.map(
                      (propriedadeFinal: any, index3: number) => {
                        if (propriedadeFinal.name === propriedades[index]) {
                          propExists = true;
                          propriedadeFinal.hidden = propriedadeFinal.hidden;
                          propriedadeFinal.data.push(dadosFinal[index]);
                        }
                      }
                    );
                  });

                  if (!propExists) {
                    aparelhoFinal.datasets.push({
                      name: propriedades[index],
                      label: propriedadesName[index],
                      hidden: false,
                      data: [dadosFinal[index]],
                    });
                  }
                }
              });
              if (!aparelhoExists) {
                dadosAtualCompare.push({
                  name: aparelho,
                  labels: [datetime[index]],
                  datasets: propriedades.map((prop, index) => ({
                    name: prop,
                    label: propriedadesName[index],
                    hidden: false,
                    data: [dadosFinal[index]],
                  })),
                });
              }
            }
          });

          // console.log("fim array final", dadosAtual);
          setDadosCompare(dadosAtualCompare);
        }
        setStartPreloader(false);
      },
      onclose() {
        console.log("Connection closed by the server");
      },
      onerror(err) {
        console.log("There was an error from server", err);
      },
    });
  }
};
