import type { LoaderFunctionArgs } from "react-router-dom";
import {
  Form,
  Link,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect,
  useFetcher,
  useRouteLoaderData,
} from "react-router-dom";
import { randomFillSync } from "crypto";
import HomePublic from "./pages/public/HomePublic/HomePublic";
import Login from "./pages/public/Login/Login";
import { Header } from "./components/Header/Header";
import Root from "./pages/Root";
import { loginAction, loginLoader } from "./loaders_and_actions/login";
import {
  recuperarAction,
  recuperarLoader,
} from "./loaders_and_actions/recuperar";
import Recuperar from "./pages/public/Recuperar/Recuperar";
import { useAuth } from "./contexts/auth";
import { protectedLoader } from "./loaders_and_actions/protected";
import { useMenu } from "./contexts/menu";
import UsersProtected from "./pages/protected/UsersProtected/UsersProtected";
import ProfileProtected from "./pages/protected/ProfileProtected/ProfileProtected";
import ChangePasswordProtected from "./pages/protected/ChangePasswordProtected/ChangePasswordProtected";
import { trocarAction } from "./loaders_and_actions/trocar";
import AddUserProtected from "./pages/protected/AddUserProtected/AddUserProtected";
import { adicionarUsuarioAction } from "./loaders_and_actions/adicionarUsuario";
import EditUserProtected from "./pages/protected/EditUserProtected/EditUserProtected";
import { editarUsuarioAction } from "./loaders_and_actions/editarUsuario";
import CategoryProtected from "./pages/protected/CategoryProtected/CategoryProtected";
import EditCategoryProtected from "./pages/protected/EditCategoryProtected/EditCategoryProtected";
import { editarCategoriaAction } from "./loaders_and_actions/editarCategoria";
import { adicionarCategoriaAction } from "./loaders_and_actions/adicionarCategoria";
import AddCategoryProtected from "./pages/protected/AddCategoryProtected/AddCategoryProtected";
import GroupsProtected from "./pages/protected/GroupsProtected/GroupsProtected";
import EditGroupsProtected from "./pages/protected/EditGroupsProtected/EditGroupsProtected";
import AddGroupsProtected from "./pages/protected/AddGroupsProtected/AddGroupsProtected";
import { editarGrupoAction } from "./loaders_and_actions/editarGrupo";
import { adicionarGrupoAction } from "./loaders_and_actions/adicionaGrupo";
import DeviceProtected from "./pages/protected/DeviceProtected/DeviceProtected";
import EditDeviceProtected from "./pages/protected/EditDeviceProtected/EditDeviceProtected";
import { editarDispositivoAction } from "./loaders_and_actions/editarDispositivo";
import AddDeviceProtected from "./pages/protected/AddDeviceProtected/AddDeviceProtected";
import {
  verDispositivoAction,
  verDispositivoHistoryAction,
} from "./loaders_and_actions/verDispositivo";
import ViewDeviceProtected from "./pages/protected/ViewDeviceProtected/ViewDeviceProtected";
import HomeProtected from "./pages/protected/HomeProtected/HomeProtected";
import Reset from "./pages/public/Reset/Reset";
import { resetAction, resetLoader } from "./loaders_and_actions/reset";
import { adicionarDispositivoAction } from "./loaders_and_actions/adicionarDispositivo";
import ViewDeviceHistoryProtected from "./pages/protected/ViewDeviceHistoryProtected/ViewDeviceHistoryProtected";
import EditAlarmProtected from "./pages/protected/EditAlarmProtected/EditAlarmProtected";
import { editarAlarmeAction } from "./loaders_and_actions/editarAlarme";

export default function App() {
  const { user, recuperar, trocar, reset, login, logout } = useAuth();
  const { setOpenMenu } = useMenu();

  const router = createBrowserRouter(
    [
      {
        id: "root",
        path: "/",
        loader() {
          return { user: user }; // Our root route always provides the user, if logged in
        },
        Component: Root,
        children: [
          {
            index: true,
            loader: protectedLoader(user, ["admin", "usuario"]),
            element: <HomeProtected />,
          },
          {
            path: "login",
            action: loginAction(login),
            loader: loginLoader(
              user === null ? false : user.token === null ? false : true
            ),
            element: <Login />,
          },
          {
            path: "recuperar",
            action: recuperarAction(recuperar),
            loader: recuperarLoader(
              user === null ? false : user.token === null ? false : true
            ),
            element: <Recuperar />,
          },
          {
            path: "reset",
            action: resetAction(reset),
            loader: resetLoader(
              user === null ? false : user.token === null ? false : true
            ),
            element: <Reset />,
          },
          {
            path: "categorias",
            loader: protectedLoader(user, ["admin"]),
            element: <CategoryProtected />,
          },
          {
            path: "adicionarcategoria",
            action: adicionarCategoriaAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <AddCategoryProtected />,
          },
          {
            path: "editarcategoria/:categoryId",
            action: editarCategoriaAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <EditCategoryProtected />,
          },
          {
            path: "grupos",
            loader: protectedLoader(user, ["admin"]),
            element: <GroupsProtected />,
          },
          {
            path: "adicionargrupo",
            action: adicionarGrupoAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <AddGroupsProtected />,
          },
          {
            path: "editargrupo/:groupsId",
            action: editarGrupoAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <EditGroupsProtected />,
          },
          {
            path: "dispositivos",
            loader: protectedLoader(user, ["admin", "usuario"]),
            element: <DeviceProtected />,
          },
          {
            path: "adicionardispositivo",
            action: adicionarDispositivoAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <AddDeviceProtected />,
          },
          {
            path: "editardispositivo/:deviceId",
            action: editarDispositivoAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <EditDeviceProtected />,
          },
          {
            path: "verdispositivo/:deviceId",
            action: verDispositivoAction(user?.token),
            loader: protectedLoader(user, ["admin", "usuario"]),
            element: <ViewDeviceProtected />,
          },
          {
            path: "verdispositivo/:deviceId/historico",
            action: verDispositivoHistoryAction(user?.token),
            loader: protectedLoader(user, ["admin", "usuario"]),
            element: <ViewDeviceHistoryProtected />,
          },
          {
            path: "editaralarme/:alarmId",
            action: editarAlarmeAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <EditAlarmProtected />,
          },
          {
            path: "usuarios",
            loader: protectedLoader(user, ["admin"]),
            element: <UsersProtected />,
          },
          {
            path: "adicionarusuario",
            action: adicionarUsuarioAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <AddUserProtected />,
          },
          {
            path: "editarusuario/:userId",
            action: editarUsuarioAction(user?.token),
            loader: protectedLoader(user, ["admin"]),
            element: <EditUserProtected />,
          },

          {
            path: "perfil",
            loader: protectedLoader(user, ["admin", "usuario"]),
            element: <ProfileProtected />,
          },
          {
            path: "trocar",
            action: trocarAction(trocar),
            loader: protectedLoader(user, ["admin", "usuario"]),
            element: <ChangePasswordProtected />,
          },
        ],
      },
      {
        path: "/logout",
        async action() {
          // We logout in a "resource route" that we can hit from a fetcher.Form
          await logout();
        },
      },
    ],
    { basename: "/" }
  );

  return (
    <RouterProvider router={router} fallbackElement={<p>Carregando...</p>} />
  );
}
