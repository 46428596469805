import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface EditAlarmContextType {
  editAlarmEquation: string;
  setEditAlarmEquation: Dispatch<SetStateAction<string>>;
}

const EditAlarmContext = createContext<EditAlarmContextType | undefined>(
  undefined
);

interface EditAlarmProviderProps {
  children: ReactNode;
}

export function EditAlarmProvider({ children }: EditAlarmProviderProps) {
  const [editAlarmEquation, setEditAlarmEquation] = useState<string>("");
  return (
    <EditAlarmContext.Provider
      value={{
        editAlarmEquation,
        setEditAlarmEquation,
      }}
    >
      {children}
    </EditAlarmContext.Provider>
  );
}

export function useEditAlarm(): EditAlarmContextType {
  const context = useContext(EditAlarmContext);
  if (!context) {
    throw new Error(
      "useEditAlarm deve ser usado dentro de um EditAlarmProvider"
    );
  }
  return context;
}
