import { useEffect, useState } from "react";
import { UsersAPI } from "../../../apis/UsersAPI";
import { useAuth } from "../../../contexts/auth";
import { AxiosError, isAxiosError } from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  PencilIcon,
  UserPlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { Container, Main } from "./styled";
import { disable } from "workbox-navigation-preload";
import { useNavigate } from "react-router-dom";
import { usePreloader } from "../../../contexts/preloader";
import { useDialog } from "../../../contexts/dialog";
import { isDesktop } from "react-device-detect";

const UsuariosProtected = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setAuthMessage } = useAuth();
  const { setStartPreloader } = usePreloader();
  const { setMessageDialog, setActionDialog } = useDialog();
  const [usuarios, setUsuarios] = useState<any | null>(null);
  const [filtros, setFiltros] = useState([
    { key: "todos", value: "Todos" },
    { key: "admin", value: "Admin" },
    { key: "usuario", value: "Usuário" },
  ]);
  const [filtroActive, setFiltroActive] = useState(0);
  const [filtroBusca, setFiltroBusca] = useState("");
  const [titulos, setTítulos] = useState(
    isDesktop
      ? ["Nome/Email", "Fone", "Nível", "Permissões", "Ações"]
      : ["Nome/Email", "Ações"]
  );

  const loadPrevPage = () => {
    console.log(usuarios?.current_page, 1);
    if (usuarios?.current_page > 1) {
      console.log("prev page");

      UsersAPI.listUsers({
        token: user?.token,
        page: usuarios.current_page - 1,
      })
        .then((res: any) => {
          setUsuarios(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }
  };

  const loadNextPage = () => {
    if (usuarios?.current_page < usuarios?.last_page) {
      UsersAPI.listUsers({
        token: user?.token,
        page: usuarios.current_page + 1,
      })
        .then((res: any) => {
          setUsuarios(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }
  };

  const editarUsuario = (id: number) => {
    navigate(`/editarusuario/${id}`);
  };

  const excluirUsuario = (id: number, name: string) => {
    setMessageDialog(`Confirma exclusão do usuário ${name}?`);
    setActionDialog(() => () => excluirUsuarioConfirma(id));
  };

  const excluirUsuarioConfirma = (id: number) => {
    setStartPreloader(true);
    UsersAPI.deleteUser({ token: user?.token, id: id })
      .then((res: any) => {
        setStartPreloader(false);
        navigate("/");
        setTimeout(() => navigate("/usuarios"), 1000);
        setAuthMessage("Usuário excluído com sucesso");
      })
      .catch((err: Error | AxiosError) => {
        setStartPreloader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    UsersAPI.listUsers({
      token: user?.token,
      page: null,
    })
      .then((res: any) => {
        console.log("usuarios", res);
        setUsuarios(res);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      });
  }, [user]);

  const row = (
    index: number,
    id: number,
    name: string,
    email: string,
    phone: string,
    roles: string[],
    permissions: string[]
  ) => {
    const isLast = index === usuarios.data.length - 1;
    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
    return (
      <tr key={name}>
        <td className={classes}>
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {name}
              </Typography>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal opacity-70"
              >
                {email}
              </Typography>
            </div>
          </div>
        </td>
        {isDesktop && (
          <>
            <td className={classes}>
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {phone}
                </Typography>
              </div>
            </td>
            <td className={classes}>
              <div className="flex flex-col">
                {roles.map((role) => (
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {role}
                  </Typography>
                ))}
              </div>
            </td>
            <td className={classes}>
              <div className="flex flex-col">
                {roles.map((role, index) =>
                  role === "admin" ? (
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      Total
                    </Typography>
                  ) : (
                    permissions.map((permission) => (
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {permission}
                      </Typography>
                    ))
                  )
                )}
              </div>
            </td>
          </>
        )}
        <td className={classes}>
          <Tooltip content="Editar usuário">
            <IconButton
              variant="text"
              className="text-secondary"
              onClick={() => editarUsuario(id)}
            >
              <PencilIcon className="h-4 w-4" />
            </IconButton>
          </Tooltip>
          <Tooltip content="Excluir usuário">
            <IconButton
              variant="text"
              className="text-danger"
              onClick={() => excluirUsuario(id, name)}
            >
              <XCircleIcon className="h-6 w-6" />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    );
  };

  return (
    <Container>
      <Main>
        <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 flex items-center justify-between gap-8">
              <div>
                <Typography variant="h5" color="blue-gray">
                  Usuários
                </Typography>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <Button
                  className="flex items-center gap-3 bg-secondary"
                  size="sm"
                  onClick={() => navigate("/adicionarusuario")}
                >
                  <UserPlusIcon strokeWidth={2} className="h-4 w-4" />
                  Cadastrar Usuário
                </Button>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <Tabs
                value={filtros[filtroActive].value}
                className="w-full md:w-max z-0"
              >
                <TabsHeader>
                  {filtros.map(({ key, value }, index) => (
                    <Tab
                      key={key}
                      value={value}
                      onClick={() => setFiltroActive(index)}
                    >
                      &nbsp;&nbsp;{value}&nbsp;&nbsp;
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
              <div className="w-full md:w-72">
                <Input
                  label="Buscar"
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  onChange={(e) => setFiltroBusca(e.target.value)}
                />
              </div>
            </div>
          </CardHeader>
          <CardBody className=" px-0">
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {titulos.map((titulo) => (
                    <th
                      key={titulo}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {titulo}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {usuarios?.data.map(
                  (
                    {
                      id,
                      name,
                      email,
                      phone,
                      roles,
                      permissions,
                    }: {
                      id: number;
                      name: string;
                      email: string;
                      phone: string;
                      roles: string[];
                      permissions: string[];
                    },
                    index: number
                  ) => {
                    if (filtros[filtroActive].key === "todos") {
                      if (filtroBusca !== "") {
                        if (
                          name.search(filtroBusca) !== -1 ||
                          email.search(filtroBusca) !== -1 ||
                          phone.search(filtroBusca) !== -1
                        ) {
                          return row(
                            index,
                            id,
                            name,
                            email,
                            phone,
                            roles,
                            permissions
                          );
                        }
                      } else {
                        return row(
                          index,
                          id,
                          name,
                          email,
                          phone,
                          roles,
                          permissions
                        );
                      }
                    } else {
                      let hasRole = false;
                      roles.map((role) => {
                        if (role === filtros[filtroActive].key) {
                          hasRole = true;
                        }
                      });
                      if (hasRole) {
                        if (filtroBusca !== "") {
                          if (
                            name.search(filtroBusca) !== -1 ||
                            email.search(filtroBusca) !== -1 ||
                            phone.search(filtroBusca) !== -1
                          ) {
                            return row(
                              index,
                              id,
                              name,
                              email,
                              phone,
                              roles,
                              permissions
                            );
                          }
                        } else {
                          return row(
                            index,
                            id,
                            name,
                            email,
                            phone,
                            roles,
                            permissions
                          );
                        }
                      }
                    }
                  }
                )}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Página {usuarios?.current_page} de {usuarios?.last_page}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="sm"
                onClick={loadPrevPage}
                disabled={usuarios?.current_page === 1}
              >
                Anterior
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={loadNextPage}
                disabled={usuarios?.current_page === usuarios?.last_page}
              >
                Próxima
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Main>
    </Container>
  );
};

export default UsuariosProtected;
