import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import { useAuth } from "../contexts/auth";
import { GroupsAPI } from "../apis/GroupsAPI";
import { DeviceAPI } from "../apis/DeviceAPI";
import { ComparisonAPI } from "../apis/ComparisonAPI";

export const editarAlarmeAction =
  (token: string | undefined) =>
  async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let is_range = formData.get("is_range") as string | null;
    let id = formData.get("id") as string | null;
    let enabled = formData.get("enabled") as string | null;
    let id_1 = formData.get("id_1") as string | null;
    let id_2 = formData.get("id_2") as string | null;
    let prop_id1 = formData.get("prop_id1") as string | null;
    let prop_id2 = formData.get("prop_id2") as string | null;
    let attr_id1 = formData.get("attr_id1") as string | null;
    let attr_id2 = formData.get("attr_id2") as string | null;
    let min = formData.get("min") as string | null;
    let max = formData.get("max") as string | null;
    let operation = formData.get("operation") as string | null;
    let tolerance = formData.get("tolerance") as string | null;
    let threshold = formData.get("threshold") as string | null;
    let mensagem = formData.get("mensagem") as string | null;
    let users = formData.get("users") as string | null;

    // let nome = formData.get("nome") as string | null;
    // let mac = formData.get("mac") as string | null;
    // let grupo = formData.get("grupo") as number | null;
    // let props = formData.get("props") as string | null;

    if (!token) {
      return {
        error: "Você não tem permissão para a ação",
      };
    }

    if (enabled === null) {
      return {
        error: "Você não tem permissão para a ação (id)",
      };
    }

    if (!id) {
      return {
        error: "Você não tem permissão para a ação (id)",
      };
    }

    if (is_range === null) {
      return {
        error: "Você não tem permissão para a ação (id)",
      };
    }

    if (!id_1) {
      return {
        error: "Verificar id_1",
      };
    }
    if (!prop_id1) {
      return {
        error: "Verificar prop_id1",
      };
    }
    if (!attr_id1) {
      return {
        error: "Verificar attr_id1",
      };
    }
    if (!tolerance) {
      return {
        error: "Verificar tolerance",
      };
    }
    if (!threshold) {
      return {
        error: "Verificar threshold",
      };
    }
    if (!mensagem) {
      return {
        error: "Verificar mensagem",
      };
    }
    if (!users) {
      return {
        error: "Verificar users",
      };
    }

    if (Number(is_range) === 0) {
      //compare
      if (!id_2) {
        return {
          error: "Verificar id_2",
        };
      }
      if (!prop_id2) {
        return {
          error: "Verificar prop_id2",
        };
      }
      if (!attr_id2) {
        return {
          error: "Verificar attr_id2",
        };
      }
      if (!operation) {
        return {
          error: "Verificar operation",
        };
      }
      min = "";
      max = "";
    } else {
      if (!min) {
        return {
          error: "Verificar min",
        };
      }
      if (!max) {
        return {
          error: "Verificar max",
        };
      }
      id_2 = "";
      prop_id2 = "";
      attr_id2 = "";
      operation = "";
    }

    try {
      let response = await (Number(is_range) === 1
        ? editarAlarmeIndividual(
            token,
            enabled,
            id,
            is_range,
            id_1,
            prop_id1,
            attr_id1,
            min,
            max,
            tolerance,
            threshold,
            mensagem,
            users
          )
        : editarAlarmeComparativo(
            token,
            enabled,
            id,
            is_range,
            id_1,
            prop_id1,
            attr_id1,
            id_2,
            prop_id2,
            attr_id2,
            operation,
            tolerance,
            threshold,
            mensagem,
            users
          ));
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return {
        error: "Problema ao editar alarme. Tente novamente",
      };
    }
  };

const editarAlarmeIndividual = async (
  token: string,
  enabled: string,
  id: string,
  is_range: string,
  id_1: string,
  prop_id1: string,
  attr_id1: string,
  min: string,
  max: string,
  tolerance: string,
  threshold: string,
  mensagem: string,
  users: string
) => {
  let dados = {
    enabled: enabled,
    is_range: is_range,
    id_1: id_1,
    prop_id1: prop_id1,
    attr_id1: attr_id1,
    min: min,
    max: max,
    tolerance: tolerance,
    threshold: threshold,
    message: mensagem,
    users: users.split(","),
  };
  const response = await ComparisonAPI.updateComparison({
    token: token,
    id: id,
    dados: dados,
  })
    .then((res: any) => {
      return res;
    })
    .catch((err: Error | AxiosError) => {
      if (isAxiosError(err)) {
        return err.response?.data.message;
      }
    });
  return response;
};

const editarAlarmeComparativo = async (
  token: string,
  enabled: string,
  id: string,
  is_range: string,
  id_1: string,
  prop_id1: string,
  attr_id1: string,
  id_2: string,
  prop_id2: string,
  attr_id2: string,
  operation: string,
  tolerance: string,
  threshold: string,
  mensagem: string,
  users: string
) => {
  let dados = {
    enabled: enabled,
    is_range: is_range,
    id_1: id_1,
    prop_id1: prop_id1,
    attr_id1: attr_id1,
    id_2: id_2,
    prop_id2: prop_id2,
    attr_id2: attr_id2,
    operation: operation,
    tolerance: tolerance,
    threshold: threshold,
    message: mensagem,
    users: users.split(","),
  };
  console.log(dados);
  const response = await ComparisonAPI.updateComparison({
    token: token,
    id: id,
    dados: dados,
  })
    .then((res: any) => {
      return res;
    })
    .catch((err: Error | AxiosError) => {
      if (isAxiosError(err)) {
        return err.response?.data.message;
      }
    });
  return response;
};
